import React, { useContext, useEffect, useReducer, useState} from "react";
import { GlobalPermissionsRes, GroupPermissionsRes } from 'server-openapi';
import {useCurrentUser} from "../authn/UserProvider";
import {useAppState} from "../../context/AppStateProvider";
import {apis} from "../mrs/apis";

interface PermissionsState extends GlobalPermissionsRes {
    userId?: string;
    global_permissions: GlobalPermissionsRes;
    facilityGroupId?: string;
    group_permissions: GroupPermissionsRes;
}

interface SetGlobalPermissions {
    type: "GLOBAL_PERMISSIONS",
    payload: {
        userId?: string,
        permissions: GlobalPermissionsRes
    };
}

interface SetGroupPermissions {
    type: "GROUP_PERMISSIONS",
    payload: {
        facilityGroupId?: string,
        permissions: GroupPermissionsRes
    };
}
interface ResetGlobal {
    type: "RESET_GLOBAL"
}
interface ResetGroup {
    type: "RESET_GROUP"
}

type Action = SetGlobalPermissions | SetGroupPermissions | ResetGlobal | ResetGroup;
function defaultUserState(): GroupPermissionsRes {
    return {
        canAddAndRevokeUser: undefined,
        canManageUser: undefined,
        canAdministerControlledDrugs: undefined,
        canAdministerMedication: undefined,
        canAdministerDoubleSigningMedication: undefined,
        canAdministerS8Medication: undefined,
        canAdministerS4Medication: undefined,
        canAdministerPackedControlledDrugs: undefined,
        canAdministerPackedMedications: undefined,
        canReAdministerMedication: undefined,
        canAdministerMissedMedication: undefined,
        canAdministerNIMAndRecordItsOutcome: undefined,
        canAdministerPRNAndRecordItsOutcome: undefined,
        canConfigureNim: undefined,
        canAdministerNonPackedMedicationsExcludingInjectableMedication: undefined,
        canAdministerNonPackedMedicationsIncludingInjectableMedication: undefined,
        canAdministerPatchMedicationExcludingS8: undefined,
        canAdministerS8PatchMedication: undefined,
        canAdministerShortCourseMedication: undefined,
        canAdministerSlidingScaleMedication: undefined,
        canAssignRolesExcludingUserManagementPermission: undefined,
        canAssignRolesWithUserManagementPermission: undefined,
        canBeSecondChecker: undefined,
        canCompleteRounds: undefined,
        canCreateRounds: undefined,
        canConfigureFrequencyForCheckingPatches: undefined,
        canConfigureLabel: undefined,
        canConfigurePermittedRoundStartPeriodWindow: undefined,
        canConfigureSecondCheck: undefined,
        canEditResidentOffsiteStatus: undefined,
        canEditResidentSpecialConsiderations: undefined,
        canEditResidentSpecialInstruction: undefined,
        canListPatients: undefined,
        canMarkResidentAsFullySelfAdministered: undefined,
        canMarkResidentMedicationAsSelfAdministered: undefined,
        canOperateSyringeDriverMedication: undefined,
        canOrderResuppliesOfMedication: undefined,
        canRecordNote: undefined,
        canRecordVitals: undefined,
        canUndertakeMedicationRound: undefined,
        canViewCorporateReport: undefined,
        canViewMedicationRound: undefined,
        canViewReport: undefined,
        canViewFacilityManagerReport: undefined,
        canViewFacilityGroupConfigurationExcludingUserManagement: undefined,
        canViewFacilityGroupConfigurationIncludingUserManagement: undefined,
        canViewMpsReport: undefined,
        canViewMetricsDashboard: undefined,
        canViewRegisteredNurseReport: undefined,
        canViewResidentNotes: undefined,
        canViewResupplyOrder: undefined,
        packedMedsDoNotNeedSecondCheck: undefined,
        canWithholdMedication: undefined,
    }
}
function defaultGlobalState(): GlobalPermissionsRes {
    return {
        canManageMaintenanceWarning: undefined,
        canRead: undefined,
        canOnboardFacilities: undefined,
    }
}
function reducer(state: PermissionsState, action: Action): PermissionsState {
    switch (action.type) {
    case "GLOBAL_PERMISSIONS":
        //console.log("Updating global permissions");
        state = {
            ...state,
            userId: action.payload.userId,
            global_permissions: action.payload.permissions
        };
        break;

    case "GROUP_PERMISSIONS":
        //console.log("Updating group permissions");
        state = {
            ...state,
            facilityGroupId: action.payload.facilityGroupId,
            group_permissions: action.payload.permissions
        };
        break;

    case "RESET_GLOBAL":
        console.log("Resetting global permissions");
        state = {
            userId: undefined,
            facilityGroupId: undefined,
            global_permissions: defaultGlobalState(),
            group_permissions: defaultUserState()
        };

    case "RESET_GROUP":
        //console.log("Resetting group permissions");
        state = {
            ...state,
            facilityGroupId: undefined,
            group_permissions: defaultUserState()
        };

    default:
        break;
    }
    return state;
};

export type PermissionsDispatchFunc = (action: Action) => void;
export type PermissionsStore = [PermissionsState, PermissionsDispatchFunc];
const defaultStore: PermissionsStore = [{
    userId: undefined,
    global_permissions: defaultGlobalState(),
    facilityGroupId: undefined,
// eslint-disable-next-line @typescript-eslint/no-empty-function
    group_permissions: defaultUserState()}, () => {
}];
const Context = React.createContext<PermissionsStore>(defaultStore);

export function useRoundFilterStore(): PermissionsStore {
    return useContext(Context);
}


export function usePermissionsState(): PermissionsState {
    return useContext(Context)[0];
}

interface PermissionsProviderProps {
    children?: React.ReactNode;
}

const STORE_NAME = 'fdc2307d-8b99-4413-8d63-f668e2ada296';

const initialState: PermissionsState = {
    userId: undefined,
    global_permissions: defaultGlobalState(),
    facilityGroupId: undefined,
    group_permissions: defaultUserState()
};

function loadPermissionsState(): PermissionsState {
    try {
        //console.log("loadPermissionsState");
        const serialized = window.localStorage.getItem(STORE_NAME);
        if (serialized) {
            //console.log("Returning serialized atob");
            return JSON.parse(window.atob(serialized));
        }
    }
    catch {
        console.error("Error occurred loadPermissionsState");
    }
    console.log("Completed loadPermissionsState");
    return initialState;
}

export function unloadPermissionsState() {
    try {
        //console.log("unloadPermissionsState");
        window.localStorage.setItem(STORE_NAME, JSON.stringify(null));
    }
    catch {
        console.error("Error occurred unloadPermissionsState");
    }
    console.log("Completed unloadPermissionsState");
}

export const PermissionsProvider: React.FC<PermissionsProviderProps> = props => {
    const permissionsState = loadPermissionsState();
    const store: PermissionsStore = useReducer(reducer, permissionsState);
    //console.log("Completed permissionsState Reducer.");
    const currentUser = useCurrentUser();
    //console.log("Completed useCurrentUser");
    const appState = useAppState();
    //console.log("Completed useAppState");

    // Any time the state changes, write it to session store.
    useEffect(() => {
        if (store[0]) {
            //console.log("Write it to session store");
            try {
                window.localStorage.setItem(STORE_NAME, btoa(JSON.stringify(store[0])));
            } catch {
                console.error("Error occurred PermissionsProvider");
            }

        }
   }, [store[0]]);

    // When the
    useEffect(() => {
        //console.log("currentUser.profile.sub changed");
        // user id has changed.
        if (currentUser.profile.sub !== store[0].userId) {
            //console.log("Reset the global store");
            // Reset the store
            store[1]({
                type: "RESET_GLOBAL"
            });

            if (currentUser.profile.sub) {
                // Update the user permissions.
                //console.log("Update the user permissions if user has changed.");
                apis.permissions.permissionsMyGlobalPermissions().then((perm) => {
                    store[1]({
                        type: "GLOBAL_PERMISSIONS",
                        payload: {
                            userId: currentUser.profile.sub,
                            permissions: perm.data
                        }
                    });
                });
            }
        }
    }, [currentUser.profile.sub]);

    useEffect(() => {
        //console.log("selectedFacilityGroupId changed");
        // facility group id has changed.
        if (appState.state.selectedFacilityGroupId !== store[0].facilityGroupId) {
            //console.log("Reset the facility group store");
            // Reset the store
            store[1]({
                type: "RESET_GROUP"
            });

            if (appState.state.selectedFacilityGroupId) {
                // Update the user permissions.
                //console.log("Update the user permissions if facility group has changed.");
                apis.permissions.permissionsMyGroupPermissions(+appState.state.selectedFacilityGroupId).then((perm) => {
                    store[1]({
                        type: "GROUP_PERMISSIONS",
                        payload: {
                            facilityGroupId: appState.state.selectedFacilityGroupId,
                            permissions: perm.data
                        }
                    });
                });
            }
        }
    }, [appState.state.selectedFacilityGroupId]);

    return (
        <Context.Provider value={store}>
            {props.children}
        </Context.Provider>
    );
};
export function useGlobalPermissions(): GlobalPermissionsRes {
    return useContext(Context)[0].global_permissions;
}
export function useGroupPermissions(): GroupPermissionsRes {
    return useContext(Context)[0].group_permissions;
}

