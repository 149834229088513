import React from 'react';
import {HSChartDose, HSDrug, HSPackedMedication, HSPackedPrnMedication, HSPatient} from 'server-openapi';
import { Layout } from '../../../../kit/Layout';
import { Image } from '../../../../kit/Image';
import styled from 'styled-components';
import { AdministrationWarningIcons, DrugWarningIcons } from '../medicationInformation/DrugDetails';
import { DateUtils } from '../../../../core/utils/dateUtils';
import { DrugUtils } from '../../../../syncstream/utils/DrugUtils';
import { Text } from '../../../../kit/Text';
import { checkIfMedicationIsSelfAdministered } from '../../../../syncstream/utils/PackedPatientDayUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {Tooltip} from "react-tooltip";
import {colors} from "../../../../kit/Theme/Theme";

interface IProps {
  drug: HSDrug;
  packedMedication?: HSPackedMedication | HSPackedPrnMedication;
  facilityGroupId: number;
  patient: HSPatient;
}

export function DrugDetailLeftPanel(props: IProps) {
    const apiUtils = useApiUtils();
    const chartItemDose = apiUtils.patients.getHSChartDoseFromPackedMedication(props.patient, props.packedMedication);

    const getDoseTimestamp = (packedMedication: HSPackedMedication | HSPackedPrnMedication) => {
        if ('doseTimestamp' in packedMedication) {
            return packedMedication.doseTimestamp;
        }
    }
    const doseTimestamp = !!props.packedMedication ? getDoseTimestamp(props.packedMedication) : undefined;

    return (
        <Layout gap={0.5}>
            {props.drug.imageUri && <DrugImage src={props.drug.imageUri} facilityGroupId={props.facilityGroupId} />}
            {!DrugUtils.drugHasNoWarning(props.drug) && (
                <DrugWarningsContainer>
                    <DrugWarningIcons displayLabels drugWarnings={DrugUtils.getDrugWarnings(props.drug)} />
                </DrugWarningsContainer>
            )}
            <DrugDetailText weight={'bold'} size="medium">
                {!!doseTimestamp && DateUtils.dateStringTo24HourTimeString(doseTimestamp)}&emsp;{props.drug.name}{' '}
                {props.drug.formAbbreviation}&emsp;{props.drug.strength}
            </DrugDetailText>
            <DrugDetailText>({props.drug.genericName})</DrugDetailText><Line></Line>
            <DrugDetailText>
                {props.packedMedication?.directions}{' '}
                {props.packedMedication?.route?.code && `: ${props.packedMedication?.route?.code}`}
            </DrugDetailText>
            {chartItemDose?.reasonForPrescribing && (
                <DrugDetailText>Reason for prescribing: {chartItemDose?.reasonForPrescribing}</DrugDetailText>
            )}
            {chartItemDose?.indications && <DrugDetailText>Indications: {chartItemDose?.indications}</DrugDetailText>}

            <Layout horizontal>
                <AdministrationWarningIcons
                    administrationWarnings={DrugUtils.getAdministrationWarnings(
                        props.drug,
                        props.packedMedication,
                        apiUtils.patients.getHSMedicationFromPackedMedication(props.patient, props.packedMedication),
                        props.packedMedication ? checkIfMedicationIsSelfAdministered(props.packedMedication, props.patient) : false,
                    )}
                />
            </Layout>
        </Layout>
    );
}

const DrugDetailText = styled(Text)`
  color: ${(p) => p.theme.backgrounds.default.fg};
`;

const DrugImage = styled(Image)`
  object-fit: cover;
  max-width: 60%;
  height: 100px;
  margin-bottom: 2em;
`;

const DrugWarningsContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
  width: fit-content;
`;

const Line = styled.hr`
    width: 100%;
    margin: .5em 0;
    border: 1px solid ${colors.bg_green_400};
`;

