/* eslint-disable max-lines-per-function */
/* eslint-disable max-lines */
import React, {useEffect, useState} from 'react';
import {Link, useHistory, useParams} from 'react-router-dom';
import _ from 'lodash';
import {
  CreateUserMessageType,
  CreateUserStatus,
  LoginType,
  RoleDto,
  UserInvitationDto,
  UserRoleAssignment,
} from 'server-openapi';
import styled from 'styled-components';
import {NavigationBar} from '../../../components/NavigationBar/NavigationBar';
import {apis} from '../../../core/mrs/apis';
import {Button, Variation} from '../../../kit/Button';
import {StyledTextInput, TextInput} from '../../../kit/Forms/TextInput';
import {Grid} from '../../../kit/Grid';
import {useAsync} from '../../../kit/hooks/UseAsync';
import {IconBack} from '../../../kit/Icons/Back';
import {useForm} from 'react-hook-form';
import {Form} from '../../../kit/Forms/Form';
import {toasts} from '../../../kit/Toasts/Toaster';
import {format} from 'date-fns';
import {Layout} from '../../../kit/Layout';
import {RolesFormSection} from './components/RolesFormSection';
import {Intent} from '../../../kit/Theme/Theme';
import {DateUtils} from '../../../core/utils/dateUtils';
import {RequirePermission} from '../../../components/RequirePermission/RequirePermission';
import {ValidationMessages} from '../../../core/utils/validationMessages';
import {Select, SelectOption} from "../../../kit/Forms/Select";
import {useGroupPermissions} from "../../../core/authz/PermissionsProvider";
import { useSyncCenter } from '../../../syncstream/SyncCenterProvider';
import { useStore } from '../../../core/storage/hooks/UseStore';
import {Tooltip} from "react-tooltip";
import { IoInformationCircle } from "react-icons/io5";

interface IParams {
  facilityGroupId: string;
  userId: string;
}
export interface RoleAccessDto {
  role: RoleDto;
  startDate: Date;
  expiryDate: Date;
  isNew: boolean;
}

export type UserDetailsFormValues = {
  firstName: string;
  familyName: string;
  email: string;
  loginType: LoginType;
  designation: string;
  roleAccess: RoleAccessDto[];
};

export function UserDetailsPage() {
  const groupPermissions = useGroupPermissions();
  return (
    <Grid cols={1} gap={1}>
      <RequirePermission hasPermission={groupPermissions.canViewFacilityGroupConfigurationIncludingUserManagement}>
        <UserDetailContent />
      </RequirePermission>
    </Grid>
  );
}

function UserDetailContent() {
  const facilityGroupId = parseInt(useParams<IParams>().facilityGroupId);
  const userId = parseInt(useParams<IParams>().userId);
  const isNewUser = useParams<IParams>().userId === 'new';
  const history = useHistory();
  const previousPagePath = `/admin/facility-group/${facilityGroupId}/settings/users`;
  const [user, setUser] = useState<UserInvitationDto>();

  useAsync(async () => {
    if (!isNewUser) {
      const response = (await apis.users.userGetUserInvitationById(userId)).data;
      setUser(response);
    }
  });

  const [roles, setRoles] = useState<RoleDto[]>([]);
  useAsync(async () => {
    setRoles((await apis.roles.roleGetAll(facilityGroupId)).data);
  });

  function goToPreviousPage() {
    history.push(previousPagePath);
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  async function onSubmit(data: UserDetailsFormValues) {
    const { firstName, familyName, email, designation, roleAccess, loginType } = data;

    const userRoleAssignments =
      roleAccess?.map((r): UserRoleAssignment => {
        return {
          roleUrn: r.role.urn!,
          startAt: DateUtils.fromDate(r.startDate),
          endAt: r.expiryDate ? DateUtils.fromDate(r.expiryDate) : undefined,
        };
      }) ?? [];
    if (!isNewUser) {
      await apis.users.userUpdateUserInvitation({
        facilityGroupId: facilityGroupId,
        id: userId,
        loginType: loginType,
        designation,
        userRoleAssignments,
      }).then(() => {
        toasts.success(`${user?.firstName} ${user?.familyName} has been ${isNewUser ? 'added' : 'updated'} successfully`);
        goToPreviousPage();
      }).catch(err => {
        toasts.error(`Error updating user: ${err}`);
      });
    } else {
      const userToInvite = {
        email,
        firstName,
        familyName,
        loginType,
        designation,
        userRoleAssignments,
      };
      await apis.users.userInviteUser({
        facilityGroupId: facilityGroupId,
        userDetails: userToInvite
      }).then((resp) => {
        if (resp.data.createUserStatus === CreateUserStatus.Created) {
          goToPreviousPage();
        }
        for(const msg of (resp.data.messages ?? [])) {
          if (msg.message) {
            let intent: Intent = Intent.Success;
            let timeout = 5;
            if (msg.messageType === CreateUserMessageType.Warning) {
              intent = Intent.Warning;
              timeout = 10;
            }
            else if (msg.messageType === CreateUserMessageType.Error) {
              intent = Intent.Danger;
              timeout = 10;
            }
            toasts.toast(msg.message, {
              intent: intent,
              timeoutSeconds: timeout
            });
          }
        }
      }).catch(err => {
        toasts.error(`Error updating user: ${err}`);
      });
    }

  }

  const activeUserRoleAssignments = user?.userRoleAssignments?.filter((ura) => !ura.softDelete);

  const form = useForm<UserDetailsFormValues>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    form.setValue('firstName', user?.firstName ?? '');
    form.setValue('familyName', user?.familyName ?? '');
    form.setValue('email', user?.email ?? '');
    form.setValue('designation', user?.designation ?? '');
    form.setValue(
      'roleAccess',
      activeUserRoleAssignments?.map((roleAssignment) => {
        const role = roles.find((r) => r.urn === roleAssignment.roleUrn);

        const startAt = roleAssignment?.startAt;
        const endAt = roleAssignment?.endAt;

        return {
          role: role,
          startDate: startAt ? DateUtils.toDate(startAt) : undefined,
          expiryDate: endAt ? DateUtils.toDate(endAt) : undefined,
          isNew: false
        };
      })
    );
  }, [user, roles]);
  return (
    <>
      <NavigationSection user={user} isNewUser={isNewUser} previousPagePath={previousPagePath} />

      <Layout gap={1} padding="20px 50px">
        <Form form={form} onSubmit={onSubmit}>
          <AccessSection user={user} isNewUser={isNewUser} setUser={setUser} facilityGroupId={facilityGroupId}/>

          {user && roles && (
            <RolesFormSection user={user} roles={roles} form={form} facilityGroupId={facilityGroupId} />
          )}

          <PageNavigationButtonContainer cols={2} gap={1}>
            <Button style={{width: '150px'}} variation={Variation.outline} intent={Intent.Secondary} onClick={goToPreviousPage} fullWidth>
              CANCEL
            </Button>
            <Button style={{width: '150px'}} type="submit" fullWidth>
              SAVE
            </Button>
          </PageNavigationButtonContainer>
        </Form>
      </Layout>
    </>
  );
}

function NavigationSection(props: { user?: UserInvitationDto; isNewUser: boolean; previousPagePath: string }) {
  const fullName = (user? : UserInvitationDto) => {
    if (!!user)
    return user.firstName + ' ' + user.familyName;
    else {
      return "";
    }
  };

  return (
    <NavigationBar
      nodes={() => ([
        <Grid colsTemplate="35px 1fr">
          <Link to={props.previousPagePath}>
            <IconBack />
          </Link>
          <h1>{props.isNewUser ? 'New User' : fullName(props.user)}</h1>
        </Grid>
      ])}
    />
  );
}

// eslint-disable-next-line sonarjs/cognitive-complexity
function AccessSection(props: {
  user?: UserInvitationDto;
  isNewUser: boolean;
  setUser: (user: UserInvitationDto) => void;
  facilityGroupId: number;
}) {
  const services = useSyncCenter();
  const facilityGroupConfigurationStore = useStore(services.facilityGroupConfigurations.store).store;

  const loginTypes = facilityGroupConfigurationStore.get(props.facilityGroupId.toString())?.loginTypes;
  if (!loginTypes) throw new Error('No Login Types found in Facility Configurations for this facilityGroup. Looks like the Facility Configurations was not synced.');

  const loginTypeOptions = loginTypes?.map((t) => ({ label: _.startCase(_.toLower(t)), value: LoginType[_.startCase(_.toLower(t)) as keyof typeof LoginType] } as SelectOption<LoginType>));

  const { user, isNewUser, setUser } = props;
  if (!user && !isNewUser) {
   return null;
  }
  return (
    <>
      <HeaderText>Access</HeaderText>
      <SectionInputsContainer padding="20px">
        <Grid colsTemplate="1fr 1fr">
          {isNewUser && (
            <>
              <Layout gap={0.5}>
                <div>FIRST NAME <span style={{ color: 'red' }}>*</span></div>
                <TextInput
                  name="firstName"
                  value={user?.firstName ?? ''}
                  onChange={(_, value) => setUser({ ...user, firstName: value })}
                  autoComplete="off"
                  fullWidth
                  required
                />
              </Layout>
              <Layout gap={0.5}>
                <div>FAMILY NAME <span style={{ color: 'red' }}>*</span></div>
                <TextInput
                  name="familyName"
                  value={user?.familyName ?? ''}
                  onChange={(_, value) => setUser({ ...user, familyName: value })}
                  fullWidth
                  required
                  autoComplete="off"
                />
              </Layout>
              <Layout gap={0.5}>
                <div>EMAIL <span style={{ color: 'red' }}>*</span></div>
                <TextInput
                  name="email"
                  value={user?.email ?? ''}
                  onChange={(_, value) => setUser({ ...user, email: value })}
                  fullWidth
                  required
                  autoComplete="off"
                  rules={{
                    validate: (email: string) =>
                      ValidationMessages.email.regex!.test(email) || ValidationMessages.email.message,
                  }}
                />
              </Layout>
            </>
          )}
          {!isNewUser && (
            <>
              <Layout gap={0.5}>
                <div>NAME</div>
                <StyledTextInput value={`${user?.firstName} ${user?.familyName}`} fullWidth disabled />
              </Layout>
              <Layout gap={0.5}>
                <div>EMAIL</div>
                <StyledTextInput value={user?.email ?? ''} fullWidth disabled />
              </Layout>
              <Layout gap={0.5}>
                <div>DATE ADDED</div>
                <StyledTextInput
                  value={user?.createdAt ? format(DateUtils.toDate(user.createdAt), 'd MMMM yyyy') : ''}
                  fullWidth
                  disabled
                />
              </Layout>
            </>
          )}
          <Layout gap={0.5}>
            <Tooltip id="login-type-tooltip" />
            <div style={{display: 'flex'}}>LOGIN TYPE <IoInformationCircle data-tooltip-id="login-type-tooltip" data-tooltip-html="<div>Local: Uses a personally managed email account.</div><div>Sso (Single Sign On): Uses a corporate managed email account.</div>" size='20px' style={{marginLeft: '15px'}} /></div>
            <Select
                name="loginType"
                options={loginTypeOptions!}
                value={user?.loginType ?? LoginType.Sso }
                onChange={(_, value) => {
                  setUser({ ...user, loginType: value });
                }}
                fullWidth
                required
            />
          </Layout>
          <Layout gap={0.5}>
            <div>DESIGNATION <span style={{ color: 'red' }}>*</span></div>
            <TextInput
              name="designation"
              autoComplete="off"
              value={user?.designation ?? ''}
              onChange={(_, value) => setUser({ ...user, designation: value })}
              fullWidth
              required
            />
          </Layout>
          {!isNewUser && (
          <Layout gap={0.5}>
            <div>SUBJECT ID</div>
            <TextInput
                name="subject-id"
                value={user?.subjectId ?? ''}
                fullWidth
                disabled
                autoComplete="off"
            />
          </Layout>
          ) }
        </Grid>
      </SectionInputsContainer>
    </>
  );
}

const HeaderText = styled.h2`
  margin-left: 20px;
`;

const SectionInputsContainer = styled(Layout)`
  background: ${(p) => p.theme.backgrounds.lighter.bg};
`;

const PageNavigationButtonContainer = styled(Grid)`
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 50px;
`;
