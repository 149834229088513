import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {colors} from "./Theme/Theme";

interface BadgeProps {
  type: string;
  text: string | number;
}

const Badge = ({type, text}: BadgeProps) => {
  const [bgColour, setBgColour] = useState('');
  const [textColour, setTextColour] = useState('');

  // add more colours if needed
  const setBadgeColour = () => {
    switch (type) {
      case 'info':
        setBgColour('#DADADA');
        setTextColour('#000000');
        break;
      case 'warning':
        setBgColour('#FFCA28');
        setTextColour('#000000');
        break;
      case 'default':
        break;
    }
  }

  useEffect(() => {
    setBadgeColour();
  }, [bgColour]);

  const BadgeContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${bgColour};
    padding: 3px 5px;
    margin: 0 5px;
    border-radius: 2px;
    color: ${textColour};
    font-weight: bold;
    font-size: 13px;
`


  return (
      <BadgeContainer>
        {text}
      </BadgeContainer>
  )
}

export default Badge;

