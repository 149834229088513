import styled, { css } from 'styled-components';
import { Button } from '../../../../kit/Button';
import { colors } from '../../../../kit/Theme/Theme';

export const PatchBodyBgImage = styled.div`
  display: flex;
  justify-content: center;
  background-image: url(${require('./mps-avatar-all-full-body.png')});
  background-repeat: no-repeat, no-repeat;
  background-size: auto 95%;
  background-position: center;
  //background-color: ${(prop) => prop.theme.backgrounds.darker.bg};
  width: 1000px;
  min-height: 800px;
  position: relative;
  margin: 2em 0;
`;

export const PatchLocationButton = styled(Button)<{
  locationNumber: number;
  isSelected?: boolean;
  recentlyPatched?: boolean;
}>`
  position: absolute;
  border-radius: 50%;
  color: white;
  outline: none;
  width: 28px;
  height: 28px;
  left: 508px;

  ${(props) => {
    if ([1, 2, 3, 4, 13, 14, 15, 16, 17, 18, 19, 20, 25, 26, 28, 29, 32, 34, 35, 38, 40, 41, 43, 46, 48, 49, 52, 54, 55, 58, 60, 61, 64, 66].includes(props.locationNumber)) {
      return css`
        background-color: ${props.theme.button.primary.bg};
      `;
    }

    if ([5, 6, 7, 8, 9, 10, 11, 12, 21, 22, 23, 24, 27, 30, 31, 33, 36, 37, 39, 42, 44, 45, 47, 50, 51, 53, 56, 57, 59, 62, 63, 65].includes(props.locationNumber)) {
      return css`
        background-color: ${colors.bg_pink_500};
      `;
    }
  }}

  ${(props) =>
    props.recentlyPatched &&
    css`
      background-color: ${colors.bg_gray_400};
    `};

  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${colors.bg_yellow_250};
    `};
`;
