import * as React from 'react';
import styled from 'styled-components';
import { Grid } from '../../../../kit/Grid';
import { Text } from '../../../../kit/Text';
import { DateUtils, Interval } from '../../../../core/utils/dateUtils';
import { useApiUtils } from '../../../../syncstream/utils/hooks/useApiUtils';
import {
  IRoundCreationPatientOption,
  IRoundCreationDoseTimeColumnOption,
  IRoundCreationPatientDoseTimeOption,
} from '../CreateRoundPage';
import { Layout } from '../../../../kit/Layout';
import { useMemo, useState } from 'react';
import { Table, TableBody, TableCell, TableColumn, TableRow } from '../../../../kit/Table';
import { Checkbox } from '../../../../kit/Checkbox';
import {ControlledDrugWarning, InsulinDrugWarning, ResidentThumbnail, TimeCriticalDrugWarning} from './ResidentRow';
import { colors } from '../../../../kit/Theme/Theme';
import { FiChevronDown, FiChevronUp } from 'react-icons/fi';
import { Tooltip } from 'react-tooltip';
import { stringifyMaxDepth } from '../../../../core/utils/stringify';


interface IProps {
  patientOptions: IRoundCreationPatientOption[];
  doseTimeOptions: IRoundCreationDoseTimeColumnOption[];
  handleSelectionChange(patientRowNumber: number, doseTimeColumnNumber: number, isSelected: boolean): void;
  handleColumnSelectionChange(doseTimeColumnNumber: number, isSelected: boolean): void;
  facilityGroupId: number;
  wingLabel: string;
}

export function RoundLeftPanel(props: IProps) {
  const {
    patientOptions,
    doseTimeOptions,
    handleSelectionChange,
    handleColumnSelectionChange,
    facilityGroupId,
    wingLabel,
  } = props;
  const visiblePatients = useMemo<IRoundCreationPatientOption[]>(() => {
    return patientOptions.filter((x) => !x.isHidden);
  }, [patientOptions]);
  const showResidentsToSeeTable = visiblePatients && visiblePatients.length > 0;
  const apiUtils = useApiUtils();
  const interval = apiUtils.rounds.getRoundWindow(new Date(), facilityGroupId);

  return (
      <ResidentsPanelContainer>
        <Grid cols={1} gap={3}>
          <Grid cols={1} gap={1}>
            <Layout horizontal align={'center'} justify="space-between" padding={'0 2em'}>
              <Text weight={'bold'}>
                {`RESIDENTS TO BE SEEN `}
                <Text weight={'normal'}>
                  {showResidentsToSeeTable
                      ? `${visiblePatients.filter((p) => p.doseTimestamps.some((dt) => dt.isSelected)).length} of ${
                          visiblePatients.length
                      }`
                      : 0}
                </Text>
              </Text>
            </Layout>
            {showResidentsToSeeTable && (
                <div data-testid="residents-to-be-seen" style={{ padding: '0 2em' }}>
                  <ResidentsToSeeList
                      patientOptions={visiblePatients}
                      doseTimeOptions={doseTimeOptions}
                      handleSelectionChange={handleSelectionChange}
                      handleColumnSelectionChange={handleColumnSelectionChange}
                      interval={interval}
                      wingLabel={wingLabel}
                      facilityGroupId={facilityGroupId}
                  />
                </div>
            )}
          </Grid>
          <Grid cols={1} gap={1}>
            <Text weight={'bold'} style={{ padding: '0 2em' }}>
              {`RESIDENTS SEEN`} <Text weight={'normal'}>0</Text>
            </Text>
          </Grid>
        </Grid>
      </ResidentsPanelContainer>
  );
}

type SortOrderType = 'PatientAsc' | 'PatientDesc' | 'RoomAsc' | 'RoomDesc';
// eslint-disable-next-line max-lines-per-function
function ResidentsToSeeList(props: {
  patientOptions: IRoundCreationPatientOption[];
  doseTimeOptions: IRoundCreationDoseTimeColumnOption[];
  handleSelectionChange(patientRowNumber: number, doseTimeColumnNumber: number, isSelected: boolean): void;
  handleColumnSelectionChange(doseTimeColumnNumber: number, isSelected: boolean): void;
  interval: Interval;
  wingLabel: string;
  facilityGroupId: number;
}) {
  const [sortOrder, setSortOrder] = useState<SortOrderType>('PatientAsc');

  const sortedPatients = useMemo<IRoundCreationPatientOption[]>(
      () => setOrder(props.patientOptions, sortOrder),
      [props.patientOptions, sortOrder],
  );

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function setOrder(
      currentPatients: IRoundCreationPatientOption[],
      newSortOrder: SortOrderType,
  ): IRoundCreationPatientOption[] {
    let patients: IRoundCreationPatientOption[] = [];

    if (newSortOrder.startsWith('Room')) {
      patients = currentPatients.sort((a, b) => {
        const result = a.wardName.localeCompare(b.wardName);
        if (result !== 0) {
          return result;
        }
        return (a.hsPatient.roomNumber ?? '').localeCompare(b.hsPatient.roomNumber ?? '');
      });
    } else {
      patients = currentPatients.sort((a, b) => {
        const result = (a.hsPatient.familyName ?? '').localeCompare(b.hsPatient.familyName ?? '');
        if (result !== 0) {
          return result;
        }
        return (a.hsPatient.givenName ?? '').localeCompare(b.hsPatient.givenName ?? '');
      });
    }
    if (sortOrder.endsWith('Desc')) {
      patients = patients.reverse();
    }
    return patients;
  }

  // eslint-disable-next-line sonarjs/cognitive-complexity
  function toggleSort(isPatient: boolean) {
    switch (sortOrder) {
      case 'PatientAsc':
        setSortOrder(isPatient ? 'PatientDesc' : 'RoomAsc');
        break;
      case 'PatientDesc':
        setSortOrder(isPatient ? 'PatientAsc' : 'RoomAsc');
        break;
      case 'RoomAsc':
        setSortOrder(isPatient ? 'PatientAsc' : 'RoomDesc');
        break;
      case 'RoomDesc':
        setSortOrder(isPatient ? 'PatientAsc' : 'RoomAsc');
        break;
    }
  }

  return (
      <ResidentsToSeeListLayout>
        <Table fullWidth>
          <thead className={'residents-to-see-header sticky'}>
          <tr>
            <TableColumn style={{ width: '30px' }} />
            <TableColumn onClick={() => toggleSort(true)}>
              <Text weight={'bold'}>NAME</Text>
              {sortOrder === 'PatientAsc' ? <FiChevronDown /> : sortOrder === 'PatientDesc' ? <FiChevronUp /> : ''}
            </TableColumn>
            <TableColumn onClick={() => toggleSort(false)}>
              <Text weight={'bold'}>{props.wingLabel.toUpperCase()}</Text>
              {sortOrder === 'RoomAsc' ? <FiChevronDown /> : sortOrder === 'RoomDesc' ? <FiChevronUp /> : ''}
            </TableColumn>
            {props.doseTimeOptions
                .filter((x) => !x.isHidden)
                .map((doseTime) => {
                  return (
                      <TableColumn key={`dt-${doseTime.idx}`}>
                        <div className={doseTime.isPartial ? 'muted-checkbox' : 'unused'}>
                          <Checkbox
                              label={DateUtils.dateTo24HourTimeString(doseTime.doseTimestamp)}
                              checked={doseTime.isSelected}
                              roundInput={true}
                              onChange={() => {
                                props.handleColumnSelectionChange(doseTime.idx, !doseTime.isSelected);
                              }}
                          />
                        </div>
                      </TableColumn>
                  );
                })}
          </tr>
          </thead>
          <TableBody>
            {sortedPatients.map((x) => {
              return (
                  <ResidentRow
                      key={`rr-${x.idx}`}
                      patientOptions={x}
                      doseTimeOptions={props.doseTimeOptions}
                      handleSelectionChange={props.handleSelectionChange}
                      interval={props.interval}
                      wingLabel={props.wingLabel}
                      facilityGroupId={props.facilityGroupId}
                  />
              );
            })}
          </TableBody>
        </Table>
      </ResidentsToSeeListLayout>
  );
}

const ResidentRow = (props: {
  patientOptions: IRoundCreationPatientOption;
  doseTimeOptions: IRoundCreationDoseTimeColumnOption[];
  handleSelectionChange(patientRowNumber: number, doseTimeColumnNumber: number, isSelected: boolean): void;
  interval: Interval;
  wingLabel: string;
  facilityGroupId: number;
}) => {
  const regex = new RegExp('^[0-9a-zA-Z]+');
  const wardCode = regex.test(props.patientOptions.wardName)
      ? regex.exec(props.patientOptions.wardName)
      : props.patientOptions.wardName.slice(0, 4);
  return (
      <TableRow key={`p-${props.patientOptions.idx}`}>
        <TableCell>
          <ResidentThumbnail src={props.patientOptions.hsPatient.imageUrl!} facilityGroupId={props.facilityGroupId} />
        </TableCell>
        <TableCell data-testid="resident-name-entry">
          <Text weight={'bold'}>{props.patientOptions.patientName}</Text>
        </TableCell>
        <TableCell>
          <Text>{`${wardCode} Rm ${props.patientOptions.hsPatient.roomNumber}`}</Text>
        </TableCell>
        {props.doseTimeOptions
            .filter((x) => !x.isHidden)
            .map((doseTime) => {
              return (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell key={`p-${props.patientOptions.idx}-dt${doseTime.idx}`}>
                    <ResidentDoseTimeCell
                        patientOption={props.patientOptions}
                        doseTimeOption={props.patientOptions.doseTimestamps[doseTime.idx]}
                        handleSelectionChange={props.handleSelectionChange}
                    />
                  </TableCell>
              );
            })}
      </TableRow>
  );
};
const ResidentDoseTimeCell = (props: {
  patientOption: IRoundCreationPatientOption;
  doseTimeOption: IRoundCreationPatientDoseTimeOption;
  handleSelectionChange(patientRowNumber: number, doseTimeColumnNumber: number, isSelected: boolean): void;
}) => {
  if (props.doseTimeOption.displayState !== 'visible') {
    return <></>;
  }
  return (
      <>
        <ResidentDoseTimeCellContainer>
          <Checkbox
              checked={props.doseTimeOption.isSelected}
              roundInput={true}
              onChange={() => {
                props.handleSelectionChange(
                    props.patientOption.idx,
                    props.doseTimeOption.idx,
                    !props.doseTimeOption.isSelected,
                );
              }}
          />
          <OverlayCenter>
            {props.doseTimeOption.scheduleItems.some((x) => x.isControlled) ? <ControlledDrugWarning /> : ''}
          </OverlayCenter>
          <OverlayTopLeft>
            {props.doseTimeOption.scheduleItems.some((x) => x.isTimeCritical) ? <TimeCriticalDrugWarning /> : ''}
          </OverlayTopLeft>
          <OverlayTopRight>
            {props.doseTimeOption.scheduleItems.some((x) => x.isInsulin) ? <InsulinDrugWarning /> : ''}
          </OverlayTopRight>
        </ResidentDoseTimeCellContainer>
      </>
  );
};

const ResidentDoseTimeCellContainer = styled.div`
{
  position: relative;
  width: 3em;
  margin: 0 auto;
}
`;

const OverlayCenter = styled.div`
{
  background-color: transparent !important;
  height: 100%;
  width: 100%;
  pointer-events: none;
  div {
    position: absolute;
    height: 75%;
    width: 75%;
    top: 0px;
    padding-left: 23%;
    padding-top: 23%;
    pointer-events: none;
    background-color: transparent !important;
  }
  * {
    background-color: transparent !important;
    pointer-events: none;
  }
`;
const OverlayTopLeft = styled.div`
{
  background-color: transparent !important;
  pointer-events: none;
  div {
    position: absolute;
    top: 0px;
    left: 0px;
    background-color: transparent !important;
    pointer-events: none;
  }
  * {
    background-color: transparent !important;
    pointer-events: none;
  }
`;

const OverlayTopRight = styled.div`
{
  background-color: transparent !important;
  pointer-events: none;
  div {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: transparent !important;
    pointer-events: none;
  }
  * {
    background-color: transparent !important;
    pointer-events: none;
  }
`;

const ResidentsPanelContainer = styled.div`
  * {
    color: ${(props) => props.theme.backgrounds.default.fg};
  }
`;
const ResidentsToSeeListLayout = styled.div`
  input,
  label {
    width: 3em;
    display: block;
    text-align: center;
    margin: 0 auto;
  }
  * {
    background-color: ${() => colors.bg_purple_700};
  }
  td:nth-of-type(odd),
  td:nth-of-type(odd) *,
  th:nth-of-type(odd),
  th:nth-of-type(odd) * {
    background-color: ${() => colors.bg_purple_800};
  }
  .residents-to-see-header {
  }
  .sticky {
    position: sticky;
    top: 0;
    z-index: 10;
  }
  div.muted-checkbox div > div {
    // eslint-disable-next-line max-lines
    opacity: 50%;
  }
  th {
    border-bottom: 3px solid ${() => colors.bg_purple_900};
    border-top: 3px solid ${() => colors.bg_purple_900};
  }
  th:first-child {
    border: none;
  }
`;