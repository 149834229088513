import React, { useEffect, useState } from "react";
import { useFormContext } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { colors, Intent } from './Theme/Theme';

// TODO: disable button during async processes

export enum Variation {
  contained, // default
  outline,
  text,
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  intent?: Intent;
  fullWidth?: boolean;
  large?: boolean;
  variation?: Variation;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void> | void;
}

export function Button(props: ButtonProps) {
  // the button component may be use inside a form as the "submit"  button.
  // and it needs to react to some form state such as when the form is submitting.
  const form = useFormContext();

  // the button component may also be given an onClick that
  // returns a promise i.e. an async callback.
  // if an async callback is provided we'll disable the button
  // while it's running to avoid accidental double clicks
  const [isClicking, setIsClicking] = useState(false);
  const [isLoaded, setIsLoaded] = useState(true);
  useEffect(() => {
    return () => setIsLoaded(false);
  }, [setIsLoaded])

  const onClick = async (event: React.MouseEvent<HTMLButtonElement>) => {
    // stop a double click
    if (isClicking) {
      return;
    }

    // call the callback
    const result = props.onClick?.(event);

    // if the callback was async (i.e. we got a promise)
    // then set clicking to true and await the promise
    // making sure to set clicking to false when it's done
    if (isLoaded && result instanceof Promise) {
      setIsClicking(true);
      try {
        await result;
      } finally {
        if (isLoaded) {
          setIsClicking(false);
        }
      }
    }
  };

  return (
    <StyledButton
      {...props}
      type={props.type ?? 'button'}
      onClick={onClick}
      disabled={form?.formState?.isSubmitting || isClicking || props.disabled}
    />
  );
}

const StyledButton = styled.button<ButtonProps>`
  cursor: pointer;
  ${(props) =>
    props.large
      ? css`
          font-size: 1.3rem;
          font-weight: bold;
          padding: 1.5rem;
        `
      : css`
          font-size: 0.8rem;
          font-weight: bold;
          padding: ${(props) => props.theme.forms.input.padding};
        `}

  display: flex;
  justify-content: center;
  align-items: center;

  text-transform: uppercase;

  border: solid;
  border-radius: ${(props) => props.theme.button.border.radius};
  border-width: ${(props) => props.theme.button.border.width};
  border-color: ${(props) => props.theme.button.border.color};

  // to make the button not expand when inside a grid
  justify-self: start;

  &:focus {
    outline: 2px ${colors.bg_blue_gray_600} solid;
    outline-offset: 0px;
  }

  &:active:after {
    outline: 2px ${colors.bg_blue_gray_600} solid;
    outline-offset: 1px;
  }
  
  ${(props) =>
    props.fullWidth &&
    css`
      width: 100%;
    `};

  ${(props) => {
    if (props.disabled) {
      return css`
        color: ${props.theme.button.disabled.fg};
        background: ${props.theme.button.disabled.bg};
        pointer-events: none;
        outline: none !important;
        border-radius: ${props.theme.button.disabled.border?.radius};
        border-width: ${props.theme.button.disabled.border?.width};
        border-color: ${props.theme.button.disabled.border?.color};
      `;
    } else if (props.intent) {
      return css`
        color: ${props.theme.button[props.intent].fg};
        background: ${props.theme.button[props.intent].bg};
        border-radius: ${props.theme.button[props.intent].border?.radius};
        border-width: ${props.theme.button[props.intent].border?.width};
        border-color: ${props.theme.button[props.intent].border?.color};
      `;
    }
    // the default style is currently "primary"
    return css`
      color: ${props.theme.button.primary.fg};
      background: ${props.theme.button.primary.bg};
      border-radius: ${props.theme.button.primary.border?.radius};
      border-width: ${props.theme.button.primary.border?.width};
      border-color: ${props.theme.button.primary.border?.color};
    `;
  }}

  ${(props) => {
    if (props.variation === Variation.text) {
      return css`
        background: none;
        border-radius: 0;
        border-width: 0;
        border-color: none;
        padding: 0 5px;
        text-transform: none;
        color: ${props.intent ? props.theme.button[props.intent].bg : 'default'};
        font-size: 1rem;
        &:focus {
          outline: none;
        }
      `;
    }
    if (props.variation === Variation.outline) {
      return css`
        background: none;
        color: ${props.intent ? props.theme.button[props.intent].border?.color : 'default'};
        border-width: 2px;
        &:focus {
          outline: none;
        }
      `;
    }
  }}
`;
