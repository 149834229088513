import React, {useEffect, useState} from 'react';
import { Layout } from '../../../../kit/Layout';
import styled, { useTheme } from 'styled-components';
import { Grid } from '../../../../kit/Grid';
import { Button } from '../../../../kit/Button';
import { FormGroup } from '../../../../kit/Forms/FormGroup';
import { Form } from '../../../../kit/Forms/Form';
import { useForm } from 'react-hook-form';
import { SyncStreamAPI } from '../../../../syncstream/api';
import { toasts } from '../../../../kit/Toasts/Toaster';
import { AxiosError } from 'axios';
import { RequirePermission } from '../../../../components/RequirePermission/RequirePermission';
import { RequireInternet } from '../../../../components/RequireInternet/RequireInternet';
import {useGlobalPermissions} from "../../../../core/authz/PermissionsProvider";
import {Checkbox} from "../../../../kit/Checkbox";
import {GlobalSettingsDto} from "server-openapi";
import {TextInput} from "../../../../kit/Forms/TextInput";

interface FormFields {
  syncUsingMrsTables: boolean;
  supportEmail: string;
}

export function GlobalSettingsForm() {
    const globalPermission = useGlobalPermissions();
    return (
        <RequirePermission hasPermission={globalPermission.canOnboardFacilities}>
            <RequireInternet>
                <GlobalSettingsFormContent />
            </RequireInternet>
        </RequirePermission>
    );
}

function GlobalSettingsFormContent() {
    const globalSettingsApi = new SyncStreamAPI().globalSettings;
    const [globalSettings, setGlobalSettings] = useState<GlobalSettingsDto>({syncUsingMrsTables: false, supportEmail: ''});
    const theme = useTheme();
    const form = useForm<FormFields>();

    useEffect(() => {
        globalSettingsApi.globalSettingsShow().then(data => {
            setGlobalSettings(data.data);
        });
    }, []);

    async function onSubmit() {
        const updateGlobalSettingsReq: GlobalSettingsDto = {
            ...globalSettings
        };

        await globalSettingsApi.globalSettingsUpdate(updateGlobalSettingsReq).catch((error: AxiosError) => {
            toasts.error(error.response?.data.detail);
            throw error;
        });

        toasts.success('Global settings updated successfully');
        form.reset();
    }



    return (
        <Layout gap={1}>
            <HeaderContainer>
                <HeaderText>Global Settings</HeaderText>
            </HeaderContainer>
            <SubTitleContainer>
                <SubTitleText>This page contains various system wide settings.</SubTitleText>
            </SubTitleContainer>
            <Layout gap={1} padding={'1.25rem'} style={{ background: theme.backgrounds.lighter.bg, maxWidth: '50rem' }}>
                <Form form={form} onSubmit={onSubmit}>
                    <Grid cols={1}>
                        <FormGroup label="Global Sync Settings" fullWidth>
                            <Checkbox
                                label="Sync from MRS Tables"
                                checked={globalSettings?.syncUsingMrsTables}
                                onChange={() => {
                                    setGlobalSettings({
                                        ...globalSettings,
                                        syncUsingMrsTables: !globalSettings?.syncUsingMrsTables,
                                    });
                                }}
                                name="syncUsingMrsTables"
                                required
                            />
                        </FormGroup>
                        <FormGroup label="Support Email" fullWidth>
                            <TextInput
                                name="globalSupportEmail"
                                onChange={(_, value) =>
                                    setGlobalSettings({ ...globalSettings, supportEmail: value })
                                }
                                // disabled={!facilityConfigPermissions.canEditFacilityLabel}
                                value={globalSettings?.supportEmail ?? undefined}
                                fullWidth
                                autoComplete="off"
                            />
                        </FormGroup>
                    </Grid>

                    <Button type={'submit'} style={{ justifySelf: 'flex-end', width: '250px' }}>
            Submit
                    </Button>
                </Form>
            </Layout>
        </Layout>
    );
}

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const HeaderText = styled.h2`
  margin: auto 0;
`;

const SubTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const SubTitleText = styled.p`
  margin: auto 0;
`;
